import React, { useEffect, useRef } from "react";
import { NavLink } from 'react-router-dom';
import './BackgroundAnimation.scss';

const setActive = ({isActive}) => ({color: isActive ? '#0071e3' : 'var(--color-active)'});


const Home = () => {

    const videoRef = useRef(null);

    const scrollToVideo = () => {
        videoRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
    const interBubble = document.querySelector('.interactive');
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;

    function move() {
      curX += (tgX - curX) / 20;
      curY += (tgY - curY) / 20;
      interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
      requestAnimationFrame(move);
    }

    const handleMouseMove = (event) => {
      tgX = event.clientX;
      tgY = event.clientY;
    };


    window.addEventListener('mousemove', handleMouseMove);

    move();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
    }, []);

    // Smooth scroll to the Learn More section
    const scrollToLearnMore = () => {
        const learnMoreSection = document.getElementById("learn-more-section");
        learnMoreSection.scrollIntoView({ behavior: 'smooth' });
    };


    
    return (
        <div className="background-animation">
        <div className="text-container-1"> Stay With Me</div>
        <div className="text-container-2"> Create AI versions of real people.  </div>
        <div className="button-container">
            <div>
                <NavLink to="/profile" >
                    <button type="button"  className="create-button" >
                        Create
                    </button>
                </NavLink>
            </div>
            <div>
                <button className="learn-button" onClick={scrollToVideo}>
                    Learn More
                </button>
            </div>
        </div>
        <div className="test" id="learn-more-section"></div>
            <div className="gradient-bg">
                <svg xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                        <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                            result="goo"
                        />
                        <feBlend in="SourceGraphic" in2="goo" />
                        </filter>
                    </defs>
                </svg>
                <div className="gradients-container">
                    <div className="g1"></div>
                    <div className="g2"></div>
                    <div className="g3"></div>
                    <div className="g4"></div>
                    <div className="g5"></div>
                    <div className="interactive"></div>
                </div>
            </div>
        <div className="click-to-learn" onClick={scrollToVideo}>
            Click here to learn how to use Stay With Me
        </div>
        <div>
            <iframe ref={videoRef} className="video" width="560" height="315" src="https://www.youtube.com/embed/rM_PyL1wKDs?si=9CTy7ZscUf0rpXLO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        </div>
                //         {/* <div className={style.talkingCircleLink}>
                //             <NavLink to="/talking-circle">
                //                 <button className={style.talkingCircleButton}>
                //                     Talk to people
                //                 </button>
                //             </NavLink>
                //         </div> */}
                        
                //         {/* <NavLink to="/signin">
                //         <button className={style.LoginButton}>
                //             Sign In
                //         </button>
                //         </NavLink> */}
                //         {/* <NavLink to="/signup" className={style.SignupButtonLink}>
                //             <button className={style.SignupButton}>
                //                 Sign Up
                //             </button>
                //         </NavLink> */}

                // {/* <div className={style.donateButton}> 
                //         <form action="https://www.paypal.com/donate" method="post" target="_top">
                //             <input type="hidden" name="business" value="A3PQEFVJU37W4" />
                //             <input type="hidden" name="no_recurring" value="0" />
                //             <input type="hidden" name="item_name" value="Building a startup from scratch. Need help covering API costs to bring our vision to life." />
                //             <input type="hidden" name="currency_code" value="USD" />
                //             <input type="image" src="./donate_paypal.png" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" className={style.donateimg} />
                //             <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" />
                //         </form>
                //     </div> */}
    );
}


export default Home;