const SET_USER_DATA = 'SET_USER_DATA';
const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

let initialState = {
    userId: null,
    login: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            console.log('SET_USER_DATA action received:', action.data);
            return {
                ...state,
                userId: action.data.userId,
                login: action.data.login
            };
        case CLEAR_USER_DATA:
            return {
                ...state,
                userId: null,
                login: null
            };
        default:
            return state;
    }
};

export const setUserData = (userId, login) => ({ type: SET_USER_DATA, data: { userId, login } });

export const clearUserData = () => ({ type: SET_USER_DATA, data: { userId: null, login: null } });

export default authReducer;
