import React, { useState, useEffect } from "react"
import { useMultistepForm } from "./useMultistepForm.ts"
import { UserForm } from "./UserForm.tsx"
import { NewForm } from "./NewForm.tsx"
import { UploadForm } from "./UploadForm.tsx"
import { UploadAudio } from "./UploadAudio.tsx"
import axios from "axios"
import style from './UploadChats.module.css'

type FormData = {
    name: string,
    gender: string,
    dateofbirth: string,
    otherName: string,
    info: string,
    file: File | null,
    audioFile: File | null,
    chatType: string,
}

const INITIAL_FORM_DATA: FormData = {
    name: "",
    gender: "",
    dateofbirth: "",
    otherName: "",
    info: "",
    file: null,
    audioFile: null,
    chatType: "",

}


export function MultistepForm() {
    const [formData, setFormData] = useState(INITIAL_FORM_DATA)
    const [msg, setMsg] = useState<string | null>(null)
    const [pendingPersonaSelection, setPendingPersonaSelection] = useState<PendingItem[]>([]);

    useEffect(() => {
        const fetchOnLoad = async () => {
            const items = await fetchPendingSelections();
            if (items.length > 0) {
                setPendingPersonaSelection(items);
            }
        };
        fetchOnLoad();
    }, [])

    type PendingItem = {
        id: string,
        detected_name_1: string,
        detected_name_2: string,
        persona_id: string,
        selected_name: string,
        userID: string,
    };

    function updateFormData(fields: Partial<FormData>) {
        setFormData(prev => {
            return {...prev, ...fields }
        })
    }

    const handleSelection = async (id: any, selectedName: any) => {
        // console.log(`Selected ID: ${id}, Name: ${selectedName}`);
        
        try {
            const response_update_selection_item = await fetch('https://kai-tech.org/update-pending-selection', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ id, selectedName }),
            });

            const updated_items = await response_update_selection_item.json();
            // console.log(updated_items);
    
            if (!response_update_selection_item.ok) { throw new Error('Failed to update selection'); }
            if(response_update_selection_item.status === 200) {
                setPendingPersonaSelection((prev) =>
                    prev.filter((item) => item.id !== id)
                );
            }
    
        } catch (error) {
            // console.error('Error updating selection:', error);
        }
    };

    const pollPendingSelections = () => {
        let attempts = 0;
        const interval = 3000;
        const maxAttempts = 5; 

        const intervalId = setInterval(async () => {
            attempts += 1;

            const items = await fetchPendingSelections();
            await items;
            if (items.length > 0) {
                setPendingPersonaSelection(items);
                clearInterval(intervalId);
            }

            if (attempts >= maxAttempts) {
                clearInterval(intervalId);
            }
        }, interval);
    };

    const fetchPendingSelections = async () => {
        try {
            const response = await fetch('https://kai-tech.org/pending-selections', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch pending selections');
            }

            const pendingItems = await response.json();
            return pendingItems.items || [];
        } catch (error) {
            console.error('Error fetching pending selections:', error);
            return [];
        }
    };

    const { steps, currentStepIndex, next, back, goTo, isFirstStep, isLastStep } = useMultistepForm([
        <UserForm {...formData} updateFormData={updateFormData} />,
        <UploadForm {...formData} updateFormData={updateFormData} />,
        <UploadAudio {...formData} updateFormData={updateFormData} />,
    ])

    function onSubmit(e: React.FormEvent) {
        e.preventDefault()
        if (isLastStep) {
            handleSubmit()
        } else {
            next()
        }
    }

    async function handleSubmit() {
        if (formData.file) {
            if (formData.file.size > 1048576 * 100) {
                setMsg("Chat zip file size exceeds 100MB");
                return;
            }
            const allowedChatTypes = ["application/zip", "application/json"];
            if (!allowedChatTypes.includes(formData.file.type)) {
                setMsg("Only zip files or json files are allowed for chat sample");
                return;
            }
        }

        if (formData.audioFile) {
            if (formData.audioFile.size > 1048576 * 10) {
                setMsg("Audio file size exceeds 10MB");
                return;
            }
            const allowedAudioTypes = ["audio/mpeg", "audio/wav", "audio/ogg"];
            if (!allowedAudioTypes.includes(formData.audioFile.type)) {
                setMsg("Only audio files (mp3, wav, ogg) are allowed for voice samples");
                return;
            }
        }

        setMsg("Uploading...");

        const fd = new FormData();
        for (const key in formData) {
            if (key === "file" || key === "audioFile") {
                if (formData[key]) {
                    fd.append(key, formData[key]);
                }
            } else {
                fd.append(key, formData[key] as string);
            }
        }

        try {
            const response = await axios.post('https://kai-tech.org/persona_creation_form', fd);
            // console.log(response.data);
            setMsg("Form submitted successfully");

            setFormData(INITIAL_FORM_DATA);
            goTo(0);
            pollPendingSelections();

        } catch (error) {
            // console.error('Error submitting form data:', error);
            setMsg("Error submitting form data: " + error.response.data.message);
        }
    }

    return (
        <>
        {pendingPersonaSelection.length > 0 && (
            <div className={style.pendingSelectionBlock}>
                    <>
                    <div className={style.pendingSelectionText}>Wait! Finish creating your persona! Select who you want to create:</div>
                    {pendingPersonaSelection.map((item) => (
                        <div className={style.pendingSelectionButtons} key={item.id}>
                            <button className={style.pendingSelectionButton} onClick={() => handleSelection(item.id, item.detected_name_1)}>
                                {item.detected_name_1}
                            </button>
                            <button className={style.pendingSelectionButton} onClick={() => handleSelection(item.id, item.detected_name_2)}>
                                {item.detected_name_2}
                            </button>
                        </div>
                    ))}
                </>
            </div> )}
        <div className={style.container}>
            <form onSubmit={onSubmit}>
                <div className={style.stepCounter}>
                    {currentStepIndex + 1 } / {steps.length}
                </div>
                {steps[currentStepIndex]}
                <div className={style.formControlButtons}>
                    { !isFirstStep && <button type="button" onClick={back} >Back</button> }
                    { !isLastStep ? <button type="submit">Next</button> : <button type="submit">Submit</button> }
                </div>

            </form>
            <div className={style.errorMessage}>{msg && <p>{msg}</p>}</div>

        </div>
        </>
    )
}
