import './App.css';
import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Header from './components/Header/Header';
import NavBar from './components/Nav/NavBar';
import Profile from './pages/Profile/Profile'; 
import ProtectedRoutes from "./utils/ProtectedRoutes";
import HomePage from './pages/Home/HomePage';
import UploadChats from './pages/UploadChats/UploadChats';
import TalkingCircle from './pages/TalkingCircle/TalkingCircle'


function App(props) {
  
  return (
        <div className="app-wrapper">
          <Header />
          <NavBar />
          <div className="app-wrapper-content">
            <Routes>
              <Route path="/" element={<HomePage />}/>

              <Route element={<ProtectedRoutes />}>
                <Route element={<UploadChats />} path="/upload-chats" exact/>
                <Route element={<Profile />} path="/profile" exact/>
              </Route>

            </Routes>

          </div>
        </div>
  );
}

export default App;
