import React, { useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUserData } from "../redux/reducers/authReducer";
import { useNavigate } from 'react-router-dom';

function navigateUserTo (url) {
  window.location.href = url;
}

const ProtectedRoutes = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleAuth = async () => {

    try {
        const response = await fetch('https://kai-tech.org/auth/verify', {
            method: 'GET',
            credentials: 'include'
        });
        console.log('response', response)
        if (response.status === 200) {
            const data = await response.json();
            console.log('data', data)            
            dispatch(setUserData(data.userId, data.login));
            if (location.pathname === '/profile' || location.pathname === '/upload-chats') {
              navigate(location.pathname); 
            } else {
              navigate('/profile');
            }

        } else {
            console.error('Error verifying token:', response.status);
            const errorResponse = await fetch('https://kai-tech.org/request', { method: 'POST' });
            const data = await errorResponse.json();
            console.log('data', data);
            navigateUserTo(data.url); 
        }
    } catch (error) {
        console.error('Error signing up:', error.message);
    }
  }

  useEffect(() => {
    handleAuth();
  }, []);

  const userId = useSelector((state) => state.auth.userId);
  const login = useSelector((state) => state.auth.login);

  console.log('Initial User ID:', userId);
  console.log('Initial Login:', login);
  

  const isAuthenticated = userId !== null && login === true;

  return isAuthenticated ? <Outlet/> : <Navigate to="/" />;
};

export default ProtectedRoutes;
