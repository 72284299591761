import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPulsing: false,
  isThinking: false,
  selectedOption: {
    name: "Roman_ENG",
    id: "default_Roman_ENG",
    language: "en-US",
    voice_id: "N0uQrdNqJrZuNMDxwcnV9"
  },
  transcript: '',
  listening: false,
  callData: [],
  audioStream: null,
};

// Create a slice of the store to manage the talking circle state
const talkingCircleSlice = createSlice({
  name: 'circle',
  initialState,
  reducers: {
    togglePulse: (state) => {
      state.isPulsing = !state.isPulsing;
    },
    setThinking: (state, action) => {
      state.isThinking = action.payload;
    },
    setSelectedOption: (state, action) => {
      // Update the selected option
      let data = {
        name: action.payload.name,
        id: action.payload.id,
        language: action.payload.language,
        voice_id: action.payload.voiceId
      }
      state.selectedOption = data;
      state.callData = []; // Reset call data when changing option
    },
    setTranscript: (state, action) => {
      // Update the transcript with the latest data from Deepgram
      console.log(action);
      state.transcript += action.payload; // Append new transcript data
    },
    setListening: (state, action) => {
      state.listening = action.payload;
    },
    addCallData: (state, action) => {
      state.callData.push(action.payload);
    },
    resetTranscript: (state) => {
      state.transcript = ''; // Reset the transcript
    },
    setAudioStream: (state, action) => {
      state.audioStream = action.payload; // Set the audio stream
    }
  }
});

// Export the actions for use in components
export const {
  togglePulse,
  setThinking,
  setSelectedOption,
  setTranscript,
  setListening,
  addCallData,
  resetTranscript,
  setAudioStream
} = talkingCircleSlice.actions;

// Selector to get the circle state
export const selectCircleState = (state) => state.circle;

// Export the reducer to be used in the store
export default talkingCircleSlice.reducer;