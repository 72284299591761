import React, { useState } from 'react'
import { FormWrapper } from './FormWrapper.tsx'
import { User } from '@azure/cosmos'
import style from './UploadChats.module.css'

type UserData = {
    name: string,
    phoneNumber: string, 
    gender: string,
    instagram_username: string,
    // dateofbirth: string,
}

type UserFormProps = UserData &{
    updateFormData: (fields: Partial<UserData>) => void,
}

export function UserForm({name, phoneNumber, gender, instagram_username, updateFormData }: UserFormProps) {
    const [formData, setFormData] = useState<UserData>({
        name: '',
        phoneNumber: '',
        gender: '',
        instagram_username: '',
    });

    return (
        <FormWrapper title='Your information'>
            <div>
                <p style={{marginBottom: "2px"}} >Add the name of the person you are cloning</p>
                <label>Persona name</label>
                <input
                    className={style.inputField}
                    placeholder='name'
                    required
                    type="text"
                    value={name}
                    onChange={(e) => updateFormData({ name: e.target.value })}
                />
            </div>
            {name && (
                <div>
                    <p style={{marginBottom: "-10px"}}>Add YOUR phone number that you will use to call the persona</p>
                    <p style={{marginBottom: "1px"}}>We support ONLY US phone numbers at this time.</p>
                    <label>Phone Number</label> 
                    <input
                        className={style.inputField}
                        placeholder='123-456-7890'
                        required
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => updateFormData({ phoneNumber: e.target.value })}
                    />
                </div>
            )}
            {phoneNumber && name && (
                <div>
                    Gender
                    <label>
                        <input
                            className={style.inputField}
                            type="radio"
                            value="m"
                            checked={gender === 'm'}
                            onChange={(e) => updateFormData({gender: e.target.value}) }
                        />
                        Male
                    </label>
                    <label>
                        <input
                            className={style.inputField}
                            type="radio"
                            value="f"
                            checked={gender === 'f'}
                            onChange={(e) => updateFormData({gender: e.target.value})}
                        />
                        Female
                    </label>
                </div>
            )}

            {phoneNumber && name && gender && (
            <div>
                <p style={{marginBottom: "2px"}}>Optional: type in instagram username of the persona for more memory data</p>
                <label>Instagram username</label>
                <div className={style.inst_username_tag}>@</div>
                <input
                    className={`${style.inputField} ${style.inst_username}`}
                    placeholder='whyiamthere'
                    type="text"
                    value={instagram_username}
                    onChange={(e) => updateFormData({ instagram_username: e.target.value })}
                />
            </div>
            )}
        </FormWrapper>
    );
}