import React from 'react';
import { motion } from 'framer-motion';
import style from './Burger.module.css';

const Burger = ({ setIsOpen, isOpen }) => {
  const menu_1_child_variants = {
    open: { transform: 'rotate(45deg)' },
    close: { transform: 'rotate(0deg)' },
  };
  const menu_2_child_variants = {
    open: { opacity: 0 },
    close: { opacity: 1 },
  };
  const menu_3_child_variants = {
    open: { transform: 'rotate(-45deg)' },
    close: { transform: 'rotate(0deg)' },
  };
  const menu_variants = {
    open: { rotate: '-360deg', transition: { duration: 0.2, delay: 0 } },
    close: { rotate: 0, transition: { duration: 0.2 } },
  };

  return (
    <motion.div
      onClick={() => setIsOpen(!isOpen)}
      className={style.burger}
      tabIndex={5}
      initial={false}
      animate={isOpen ? 'open' : 'close'}
      variants={menu_variants}
    >
      <motion.div
        animate={isOpen ? 'open' : 'close'}
        variants={menu_1_child_variants}
        className={style.stripes}
      ></motion.div>
      <motion.div
        animate={isOpen ? 'open' : 'close'}
        variants={menu_2_child_variants}
        className={style.stripes}
      ></motion.div>
      <motion.div
        animate={isOpen ? 'open' : 'close'}
        variants={menu_3_child_variants}
        className={style.stripes}
      ></motion.div>
    </motion.div>
  );
};

export default Burger;
