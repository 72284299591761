import React from "react"
import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { clearUserData } from "../../redux/reducers/authReducer"
import { useNavigate } from "react-router-dom"
import style from './Header.module.css'
import Burger from './Burger/Burger';


const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userId = useSelector((state) => state.auth.userId);
    const login = useSelector((state) => state.auth.login);

    const visibleChildrenCount =
    (location.pathname !== "/" ? 1 : 0) +
    (userId && login ? 1 : 0) +
    (!userId && !login ? 1 : 0);

    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown((prevState) => !prevState);
    };

    const closeDropdown = () => {
        navigate("/profile");
        setShowDropdown(false);
    };

    const handleLogout = async () => {
        try {
            const response = await fetch("https://kai-tech.org/auth/logout", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                dispatch(clearUserData());

                navigate("/");
            } else {
                console.error("Failed to log out:", response.statusText);
            }
        } catch (err) {
            console.error("Logout error:", err.message);
        }
    };
    
    return (
        <div className={`${style.headerBlock} ${visibleChildrenCount === 1 ? style.singleElement : ""}`}>
            {location.pathname !== "/" && (
                <NavLink to={"/"} className={style.homeNavLink}>StayWithMe</NavLink>
            )}
            
            {!userId && !login && (<NavLink to={"/profile"}><button className={style.loginLogoutButton}>Login</button></NavLink>)}

            { userId && login && (
                <div className={style.profileMenu}>
                    <Burger setIsOpen={setShowDropdown} isOpen={showDropdown} />

                    {showDropdown && (
                    <div className={style.dropdown}>
                        <button className={style.dropdownItem} onClick={closeDropdown}>
                            Profile
                        </button>
                        
                        <button
                            className={style.dropdownItem}
                            onClick={() => {
                                handleLogout();
                                closeDropdown();
                            }}
                        >
                            Logout
                        </button>
                  </div>
                )}
              </div>
            ) }
        </div>
    )
}


export default Header