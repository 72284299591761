import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import personasReducer from './reducers/personasReducer';
import setActivePersonaReducer from './reducers/setActivePersonaReducer';
import talkingCircleSlise from './slices/talkingCircleSlice';


const rootReducer = combineReducers({
    auth: authReducer,
    personas: personasReducer,
    activePersona: setActivePersonaReducer,
    circle: talkingCircleSlise
});

export default configureStore({ reducer: rootReducer }); 

