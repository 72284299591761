import React, { useState, useEffect, useRef } from 'react';
import { FormWrapper } from './FormWrapper.tsx';
import axios from 'axios';
import style from './UploadChats.module.css'


type UploadData = {
    audioFile: File | null,
};

type AudioUploadFormProps = UploadData & {
    updateFormData: (fields: Partial<UploadData>) => void,
};

export function UploadAudio({ audioFile, updateFormData }: AudioUploadFormProps) {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }, [audioFile]);

    
    return (
        <FormWrapper title="Upload audio files">
            <p style={{}}>Important: Please upload clear voice sample audio of the person you want to clone. The audio should be how person speaks naturally:</p>
            <input ref={fileInputRef} onChange={(e) => updateFormData({ audioFile: e.target.files ? e.target.files[0] : null })} type="file" id="audioFile" name="audioFile" />
            <label className={style.uploadButton} htmlFor="audioFile">Choose an audio file</label>
            {audioFile && <p>Selected file: {audioFile.name}</p>}
        </FormWrapper>
        
    );
}
