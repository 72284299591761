
let initialState = {
    list: [],
    loading: false,
    error: null
};
  
const personasReducer = (state = initialState, action) => {
switch (action.type) {
    case 'FETCH_PERSONAS_START':
        console.log('FETCH_PERSONAS_START action received:', action);
        return {
            ...state,
            loading: true,
            error: null
        };
    case 'FETCH_PERSONAS_SUCCESS':
        console.log('FETCH_PERSONAS_SUCCESS action received:', action);
        return {
            ...state,
            list: action.payload,
            loading: false
        };
    case 'FETCH_PERSONAS_FAILURE':
        console.log('FETCH_PERSONAS_FAILURE action received:', action);
        return {
            ...state,
            loading: false,
            error: action.payload
        };
    case 'SELECT_PERSONA':
        console.log('SELECT_PERSONA action received:', action);
        return {
            ...state,
        };
    default:
        return state;
}};

export const fetchPersonasStart = () => ({
    type: 'FETCH_PERSONAS_START'
});
  
export const fetchPersonasSuccess = (personas) => ({
    type: 'FETCH_PERSONAS_SUCCESS',
    payload: personas
});

export const fetchPersonasFailure = (error) => ({
    type: 'FETCH_PERSONAS_FAILURE',
    payload: error
});

export const selectPersona = (persona) => ({
    type: 'SELECT_PERSONA',
    payload: persona
});

export const fetchPersonas = () => {
    return async (dispatch) => {
        dispatch(fetchPersonasStart());
        try {
            const response = await fetch('https://kai-tech.org/get-persona-list', {
                method: 'POST',
                credentials: 'include', 
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch personas');
            }

            const data = await response.json();
            dispatch(fetchPersonasSuccess(data.data)); 
        } catch (error) {
            dispatch(fetchPersonasFailure(error.message));
        }
    };
};
  

export default personasReducer;