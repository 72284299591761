// Initial State
const initialState = {
    activating: false,
    activationError: null,
    isModalOpen: false,
    isPhoneNumberInputVisible: false,
    selectedPersona: null,
    phoneNumber: '',
};

// Action Types
const SET_ACTIVE_PERSONA_START = 'SET_ACTIVE_PERSONA_START';
const SET_ACTIVE_PERSONA_SUCCESS = 'SET_ACTIVE_PERSONA_SUCCESS';
const SET_ACTIVE_PERSONA_FAILURE = 'SET_ACTIVE_PERSONA_FAILURE';
const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const SHOW_PHONE_INPUT = 'SHOW_PHONE_INPUT';
const HIDE_PHONE_INPUT = 'HIDE_PHONE_INPUT';
const SET_SELECTED_PERSONA = 'SET_SELECTED_PERSONA';
const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';

// Reducer
const setActivePersonaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_PERSONA_START:
            return { ...state, activating: true, activationError: null };
        case SET_ACTIVE_PERSONA_SUCCESS:
            return {
                ...state,
                activating: false,
                isModalOpen: false,
                isPhoneNumberInputVisible: false,
                phoneNumber: '',
                selectedPersona: null,
            };
        case SET_ACTIVE_PERSONA_FAILURE:
            return { ...state, activating: false, activationError: action.payload };

        case OPEN_MODAL:
            return { ...state, isModalOpen: true };
        case CLOSE_MODAL:
            return { ...state, isModalOpen: false, selectedPersona: null };
        case SHOW_PHONE_INPUT:
            return { ...state, isPhoneNumberInputVisible: true };
        case HIDE_PHONE_INPUT:
            return { ...state, isPhoneNumberInputVisible: false, phoneNumber: '' };
        case SET_SELECTED_PERSONA:
            return { ...state, selectedPersona: action.payload };
        case SET_PHONE_NUMBER:
            return { ...state, phoneNumber: action.payload };

        default:
            return state;
    }
};

// Action Creators
export const setActivePersonaStart = () => ({ type: SET_ACTIVE_PERSONA_START });
export const setActivePersonaSuccess = () => ({ type: SET_ACTIVE_PERSONA_SUCCESS });
export const setActivePersonaFailure = (error) => ({
    type: SET_ACTIVE_PERSONA_FAILURE,
    payload: error,
});

export const openModal = () => ({ type: OPEN_MODAL });
export const closeModal = () => ({ type: CLOSE_MODAL });
export const showPhoneInput = () => ({ type: SHOW_PHONE_INPUT });
export const hidePhoneInput = () => ({ type: HIDE_PHONE_INPUT });
export const setSelectedPersona = (persona) => ({
    type: SET_SELECTED_PERSONA,
    payload: persona,
});
export const setPhoneNumber = (phoneNumber) => ({
    type: SET_PHONE_NUMBER,
    payload: phoneNumber,
});

// Thunk to Activate Persona
export const activatePersona = (personaId, phoneNumber) => async (dispatch) => {
    dispatch(setActivePersonaStart());
    try {
        const response = await fetch('https://kai-tech.org/set-active-persona', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ personaId, phoneNumber }),
        });

        if (!response.ok) throw new Error('Failed to activate persona');
        dispatch(setActivePersonaSuccess());
    } catch (error) {
        dispatch(setActivePersonaFailure(error.message));
    }
};

export default setActivePersonaReducer;
