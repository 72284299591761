import React from 'react';
import style from './ProfileComponent.module.css';
import { NavLink } from 'react-router-dom';
import personaImage from "../../assets/images/persona1.png";


const ProfileComponent = ({ 
    personas,
    loading,
    error,
    onPersonaClick,
    isModalOpen,
    selectedPersona,
    onConfirmActivation,
    onCancelActivation,
    isPhoneNumberInputVisible,
    phoneNumber,
    onPhoneNumberChange,
    onPhoneNumberSubmit,
    onActivate,
    onVoiceMailToggle
 }) => {

    return (
        <div className={style.bodyElement}>
            <div className={style.text}>
                <div className={style.welcomeText}>Welcome to StayWithMe!</div>
                <div>The app is being rapidly developed, so expect frequent updates with new features & improvements!</div>
                <div className={style.contactUs}> Contact us: ‎ 
                <a href="mailto:staywithme.contactus@gmail.com">staywithme.contactus@gmail.com</a> </div>

                <div className={style.talkOptionsText}> Talk via phone</div>
                <div className={style.activatePersonaText}>Activate default persona in the section below and call: ‎ 
                <a href="tel:+15209993998">+1 (520)-999-3998</a></div>
            </div>

            <div>
                <NavLink to="/upload-chats">
                    <button className={style.buttonUploadChats}>Create new persona</button>
                </NavLink>
            </div>
            
            {personas.length > 0 ? (<div>
                <p>Click on persona to activate it:</p>
            </div> ) : null}   
            <div className={style.personasList}>
                {loading && <p>Loading personas...</p>}

                {!loading && !error && personas.length > 0 ? (
                    <div className={style.personaButtonsContainer}>
                    {personas.map((persona) => (
                        <button 
                            key={persona.id} 
                            className={style.personaButton + (persona.isActive ? ` ${style.activeGlow}` : '' + (persona.created ? '' : ` ${style.unfinished_persona}`))}
                            onClick={() => {
                                if (persona.created){
                                    onPersonaClick(persona);
                                }
                                else{
                                    console.log('This persona is not finished yet. Please wait.');
                                }
                            }}
                        >
                            <img 
                                src={personaImage}
                                alt={persona.name} 
                                className={style.personaImage} 
                            />
                            <span className={style.personaName}>{persona.name}</span>
                            {!persona.created && (<div className={style.unfinished_text}>creating ...</div>)}
                        </button>
                    ))}
                </div>
                ) : (
                    <div className={style.noPersonaText}>No personas available, create your own above</div>
                )}
            </div>   

            <div>
                {isModalOpen && (
                    <div className={style.modalOverlay}>
                        <div className={style.modalWindow}>

                            {isModalOpen && selectedPersona?.isActive && (
                                <>
                                    {
                                        !selectedPersona?.id.startsWith("default_") &&
                                            (<div className={style.voiceMailBlock}>
                                                <div className={`${style.modalText}`}>Smart voicemail</div>

                                                <input
                                                    type="checkbox"
                                                    checked={selectedPersona?.smart_voicemail}
                                                    onChange={onVoiceMailToggle}
                                                    className={`${style.inlineBlock}`}
                                                />
                                            </div>
                                            )
                                    }   

                                    <p className={style.modalText}>{selectedPersona?.name} is already active</p>
                                    <button className={`${style.modalButton} ${style.modalButtonCancel}`} onClick={onCancelActivation}>
                                        Close
                                    </button>
                                </>
                            )}

                            {isModalOpen && !selectedPersona?.isActive && (<>
                                <p className={style.modalTitle}>Activate Persona</p>
                                <p className={style.modalText}>Do you want to activate {selectedPersona?.name}?</p>
                                {selectedPersona?.id?.startsWith('default_') && (
                                    <p className={style.modalNote}>
                                        The default persona is limited in features like long-term memory and other capabilities. 
                                        For a better experience, we encourage you to create your own persona.
                                    </p>
                                )}
                                {(isModalOpen && !isPhoneNumberInputVisible )? (<button className={`${style.modalButton} ${style.modalButtonConfirm}`} onClick={onConfirmActivation}>
                                    Yes
                                </button>): null}
                                <button className={`${style.modalButton} ${style.modalButtonCancel}`} onClick={onCancelActivation}>
                                    Cancel
                                </button>
                                {isPhoneNumberInputVisible && (
                                <div className={style.phoneInputContainer}>
                                    <div className={style.phoneNumberEntryText}>Your phone number:</div>
                                    <input
                                        type="number"
                                        required
                                        value={phoneNumber}
                                        onChange={onPhoneNumberChange}
                                        placeholder='123-456-7890'
                                        className={style.phoneInput}
                                    />
                                    
                                    <button className={style.phoneInputSubmit} onClick={(e) => {e.preventDefault(); onPhoneNumberSubmit(); onActivate(); }} onTouchEndCapture={(e) => {e.preventDefault(); onPhoneNumberSubmit(); onActivate(); }}>
                                        Activate
                                    </button>
                                </div>
                                )}
                            </>)}
                            
                        </div>
                    </div>
                )}
            </div>


            {/* <div className={style.talkWeb}>
                Talk on web
                <div>
                    <NavLink to="/talk">
                        <button className={style.buttonTalk}>Start Talking</button>
                    </NavLink>
                </div>
            </div> */}
        </div>      
)};

export default ProfileComponent;