import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import TalkingCircleComponent from "./TalkingCircleComponent";
import { fetchPersonas } from '../../redux/reducers/personasReducer';
import {
  togglePulse,
  setThinking,
  setSelectedOption,
  addCallData,
  setAudioStream,
  selectCircleState,
  setTranscript,
  resetTranscript
} from '../../redux/slices/talkingCircleSlice';
import { io } from "socket.io-client";
import { createClient, LiveTranscriptionEvents } from "@deepgram/sdk";

const deepgram = createClient(process.env.REACT_APP_DEEPGRAM_API_KEY);
const socket = io('https://kai-tech.org');

const TalkingCircleComponentContainer = () => {
    const dispatch = useDispatch();
    const {
        isPulsing,
        isThinking,
        selectedOption,
        options,
        callData,
        transcript,
    } = useSelector(selectCircleState);
    const personas = useSelector((state) => state.personas.list)

    const [isListening, setIsListening] = useState(false);
    const liveClient = useRef(null);
    const mediaRecorder = useRef(null);
    const audioContext = useRef(new (window.AudioContext || window.webkitAudioContext)());
    const audioQueue = useRef([]);
    const isPlaying = useRef(false);
    const currentAudio = useRef(null);
    const [displayText, setDisplayText] = useState("‎");
    const [deviceId, setDeviceId] = useState(null); // State for the selected microphone
    const [availableMicrophones, setAvailableMicrophones] = useState([]);



    useEffect(() => {
        dispatch(fetchPersonas());
        if (!selectedOption) {
            dispatch(setSelectedOption(options[0].name));
        }

        const getBestMicrophone = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const audioInputs = devices.filter(device => device.kind === 'audioinput');
                setAvailableMicrophones(audioInputs);

                // Try to get the default system microphone first
                let selectedDevice = audioInputs.find(device => device.deviceId === 'default');

                if (!selectedDevice) {
                    // If no default device, try to find the best available option
                    selectedDevice = audioInputs.find(device => 
                        device.label.toLowerCase().includes('built-in') ||
                        device.label.toLowerCase().includes('internal')
                    ) || audioInputs[0];
                }

                if (selectedDevice) {
                    setDeviceId(selectedDevice.deviceId);
                    console.log('Selected microphone:', selectedDevice.label);
                } else {
                    console.error('No microphone found');
                }
            } catch (error) {
                console.error('Error getting microphones:', error);
            }
        };

        // Listen for device changes
        navigator.mediaDevices.addEventListener('devicechange', getBestMicrophone);
        getBestMicrophone();

        socket.on('audio-stream', handleAudioStream);
        socket.on('llm-response-end', handleResponseEnd);

        return () => {
            socket.off('audio-stream');
            socket.off('llm-response-end');
            navigator.mediaDevices.removeEventListener('devicechange', getBestMicrophone);
            if (liveClient.current) {
                liveClient.current.requestClose();
            }
            // Clean up any playing audio
            if (currentAudio.current) {
                currentAudio.current.pause();
                currentAudio.current = null;
            }
            audioQueue.current = [];
            isPlaying.current = false;
        };
    }, [dispatch]);

    const handleAudioStream = (audioChunk) => {
        if (!audioChunk || audioChunk.trim() === '') {
            console.warn('Received empty audio chunk.');
            setDisplayText("Listening...");
            return;
        }
        audioQueue.current.push(audioChunk);
        
        if (!isPlaying.current) {
            setDisplayText("Responding...");
            playNextChunk();
        }
    };

    const handleResponseEnd = () => {
        // Ensure all remaining audio is played
        if (!isPlaying.current && audioQueue.current.length > 0) {
            playNextChunk();
        }
    };

    const base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const playNextChunk = async () => {
        if (audioQueue.current.length === 0) {
            isPlaying.current = false;
            return;
        }

        isPlaying.current = true;
        const audioChunk = audioQueue.current.shift();
        console.log('Playing audio chunk:', audioChunk.length);
        try {
            const audioBuffer = base64ToArrayBuffer(audioChunk);
            const audioBlob = new Blob([audioBuffer], { type: 'audio/mpeg' });
            const audioUrl = URL.createObjectURL(audioBlob);

            // Clean up previous audio element
            if (currentAudio.current) {
                currentAudio.current.pause();
                URL.revokeObjectURL(currentAudio.current.src);
            }

            // Create and configure new audio element
            const audio = new Audio(audioUrl);
            currentAudio.current = audio;

            audio.onended = () => {
                URL.revokeObjectURL(audioUrl);
                currentAudio.current = null;
                // Small delay before playing next chunk to prevent overlapping
                setTimeout(() => {
                    playNextChunk();
                }, 10);
            };

            audio.onerror = (error) => {
                console.error("Error playing audio:", error);
                URL.revokeObjectURL(audioUrl);
                currentAudio.current = null;
                playNextChunk(); // Try next chunk on error
            };

            await audio.play();
        } catch (error) {
            console.error("Error in playNextChunk:", error);
            playNextChunk(); // Try next chunk on error
        }
    };


    const handleClearTranscript = () => {
        dispatch(resetTranscript()); // Dispatch action to clear transcript
    };

    const startListening = async () => {
        // Request microphone access

        if (!deviceId) {
            console.error("No audio input device selected.");
            return;
        }

        const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
                deviceId: { exact: deviceId },
            },
        });

        // Define Deepgram transcription options
        const transcriptionOptions = {
            model: "nova-2", 
            language: selectedOption.language,
            punctuate: true, 
            interim_results: false, // To get only final results
            endpointing: true // To allow endpoint detection (pause detection)
        };
        
        // Initialize Deepgram client
        liveClient.current = deepgram.listen.live(transcriptionOptions);


        // Set up event listeners for transcription
        liveClient.current.on(LiveTranscriptionEvents.Open, () => {
            console.log("Connection opened to Deepgram");
            setDisplayText("Listening...");
        });

        liveClient.current.on(LiveTranscriptionEvents.Close, () => {
            console.log("Connection closed");
                setDisplayText("‎");
        });

        liveClient.current.on(LiveTranscriptionEvents.Transcript, (data) => {
            const newTranscript = data.channel.alternatives[0].transcript;
            console.log("Transcript: ", newTranscript);
            dispatch(setTranscript("")); // Reset the transcript
            console.log("Selected Option: ", selectedOption);
            if (newTranscript) {
                const payload = {
                    transcript: newTranscript,
                    name: selectedOption.name,
                    id: selectedOption.id,
                    language: selectedOption.language,
                    voiceId: selectedOption.voice_id
                }
                console.log("Payload: ", payload);
                socket.emit('transcription', payload); // Send transcript to server
                dispatch(setTranscript(newTranscript)); 
            }
            else {
                setDisplayText("Listening...");
            }
        });

        liveClient.current.on(LiveTranscriptionEvents.Error, (error) => {
            console.error("Error: ", error);
        });

        // Create a MediaRecorder to capture audio
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (event) => {
            if (liveClient.current && liveClient.current.getReadyState() === 1) {
                liveClient.current.send(event.data);
            }
        };

        // Start recording
        mediaRecorder.current.start(100); // Send data every 100ms
        setIsListening(true);
    };

    const stopListening = () => {
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            mediaRecorder.current.stream.getTracks().forEach(track => track.stop()); // Stop the microphone
            setDisplayText("‎");
        }

        if (liveClient.current) {
            liveClient.current.requestClose(); // Close the WebSocket connection
            setIsListening(false);
        }
    };

    const handleTogglePulse = () => {
        dispatch(togglePulse());
        if (!isPulsing) {
            startListening(); // Start Deepgram listening
        } else {
            stopListening(); // Stop Deepgram listening
            dispatch(addCallData({ name: 'USER', message: "User ended transcription." })); // Example dispatch
        }
    };

    const handleSelectChange = (event) => {
        const selectedPersonaName = event.target.value; 
        const selectedPersona = personas.find(persona => persona.name === selectedPersonaName);
        if (selectedPersona) {
            dispatch(setSelectedOption(selectedPersona)); 
            // dispatch(setSelectedOption({ name: selectedPersona.name, id: selectedPersona.id }));
        }
    };

    
    return (
        <TalkingCircleComponent 
            selectedOption={selectedOption} 
            handleSelectChange={handleSelectChange} 
            options={options}
            isPulsing={isPulsing}
            isThinking={isThinking}
            handleTogglePulse={handleTogglePulse}
            transcript={transcript}
            displayText={displayText}
            personas={personas}
        />
    );
};

export default TalkingCircleComponentContainer;